.btn {
    font-family: HelveticaNeueLTStd, serif;
  font-size: $menu-text-height+px;
    font-weight: bolder;
    line-height: 52px;

    width: 154px;
    height: 50px;

    border: none;
    background: none;

  &.btn-bleu {
        color: rgb(231, 231, 231);
        background-color: rgb(77, 146, 195);
    }
  &.btn-rouge {
    color: rgb(231, 231, 231);
    background-color: rgb(195, 1, 32);
  }

  &.btn-md {
    margin-left: 10px;
    font-size: ($menu-text-height - 5)+px;
  }
}

a.btn {
  padding: 12px 15px 8px;

  text-align: center;

  @extend .btn;
}

input.btn {
  @extend .btn;
}

div.btn-group {
  @extend %clearfix;
  > a {
    padding: 0;
  }
  > div,
  a {
    float: left;
  }
}

@include media('<tablet') {
  .btn {
    display: inline-block;

    margin-bottom: 5px;
    padding: 0;
  }
}
