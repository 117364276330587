.container {
    min-height: 50%;
    margin-top: 15px;
    padding: 30px;

    background-color: #fff;

    @extend %clearfix;
  .content--left--col-1 {
        float: left;
    img {
            width: 500px;
        }
    }
  .content--left--col-2 {
        float: right;

        max-width: 700px;
    }
  .content--right--col-1 {
        float: left;

        max-width: 750px;
    }
  .content--right--col-2 {
        float: right;
    img {
            width: 365px;
        }
    }
  @include media('<desktop') {
    .content--left--col-1 {
      float: left;
      width: 40%;
      img {
        width: 100%;
      }
    }
    .content--left--col-2 {
      float: right;

      max-width: 55%;
    }
    .content--right--col-1 {
      float: left;

      max-width: 55%;
    }
    .content--right--col-2 {
      float: right;
      width: 40%;

      img {
        width: 100%;
      }
    }
  }
  @include media('<tablet') {
    .content--left--col-1 {
      float: inherit;
      width: 100%;
    }
    .content--left--col-2 {
      float: inherit;
      max-width: 100%;
    }
    .content--right--col-1 {
      float: inherit;

      max-width: 100%;
    }
    .content--right--col-2 {
      float: inherit;
      width: 100%;
      margin-top: 20px;
    }
  }

  h2 {
        font-family: HelveticaNeueLTStd, serif;
        font-size: 32px;
        font-weight: 400;

        margin: 30px 0 0;
        //margin-bottom: 5px;
        padding: 0;
    &:first-of-type {
            margin-top: 0;
        }
    }
  p {
        font-family: HelveticaNeueLTStd, serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 28px;

        margin: 0;
        padding: 0;

        color: black;
    a {
      text-decoration: none;

      color: black;
    }
    }
;

  ul {
        font-family: HelveticaNeueLTStd, serif;
        font-size: 21px;
        line-height: 28px;
        // Base text style

        padding: 0;

        list-style-position: inside;
        // Text style for "• Lorem ip"

        color: rgb(1, 105, 178);
    }
}

.container-form {
    min-height: 250px !important;
}
