/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/* HTML5 display definitions
       ========================================================================== */
/**
     * Add the correct display in IE <10.
     * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
     * Add the correct display in IE for `main`.
     */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
     * Add the correct display in IE <10.
     */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
     * Add the correct display and remove excess height in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  vertical-align: baseline; }

/**
     * Add the correct display in IE <11, Safari <8, and Firefox <22.
     * 1. Add the correct display in IE.
     */
template,
[hidden] {
  display: none; }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox <40.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/**
     * Add the correct font style in Android <4.4.
     */
dfn {
  font-style: italic; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 0.75em 0; }

/**
     * Add the correct background and color in IE <10.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Remove the border on images inside links in IE <11.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
       ========================================================================== */
/**
     * Known issues:
     * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
     *   select, unless a border property is set. The default font weight on
     *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
     *   OS X.
     * - It is recommended that you do not style checkbox and radio inputs as
     *   Firefox's implementation does not respect box-sizing, padding, or width.
     * - Certain font size values applied to number inputs cause the cursor style of
     *   the decrement button to change from default to text.
     * - The search input is not fully stylable by default. In Chrome and Safari on
     *   OSX/iOS you can't control font, padding, border, or background. In Chrome
     *   and Safari on Windows you can't control border properly. It will apply
     *   border-width but will only show a border color (which cannot be controlled)
     *   for the outer 1px of that border. Applying -webkit-appearance: textfield
     *   addresses these issues without removing the benefits of search inputs (e.g.
     *   showing past searches). Safari (but not Chrome) will clip the cancel button
     *   on when it has padding (and textfield appearance).
     */
/**
     * 1. Change font properties to `inherit` in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
     * 4. Improve appearance and consistency with IE 6/7.
     */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE <11.
     * 2. Remove the padding in IE <11.
     * 3. Remove excess padding in IE 7.
     *    Known issue: excess padding remains in IE 6.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * Correct the text style of placeholders in Chrome, Edge, and Safari.
     */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     * 4. Correct alignment displayed oddly in IE 6/7.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */ }

/**
     * Restore the font weight unset by a previous rule.
     */
optgroup {
  font-weight: bold; }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* VARIABLES */
/* VARIABLES menu */
/* VARIABLES breakpoints */
@font-face {
  font-family: 'HelveticaNeueLTStd';
  font-weight: normal;
  font-style: normal;
  src: local("Helvetica Neue LT Std 45 Light"), url("fonts/HelveticaNeueLTStd Lt.woff"); }

.content:after, .row:after, .clearfix:after, div.btn-group:after, .container:after, footer:after {
  content: "";
  display: table;
  clear: both; }

html,
body,
.content {
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  background-color: #e7e7e7; }

* {
  box-sizing: border-box; }

a {
  text-decoration: none; }

.content {
  width: 1290px;
  height: auto;
  max-height: 80%;
  margin: 0 auto; }
  @media (max-width: 1023px) {
    .content {
      width: 100%;
      max-height: inherit; } }

.row {
  width: 100%; }
  .row .cols-1,
  .row .cols-2,
  .row .cols-3,
  .row .cols-4,
  .row .cols-5,
  .row .cols-6,
  .row .cols-7,
  .row .cols-8,
  .row .cols-9,
  .row .cols-10,
  .row .cols-11,
  .row .cols-12 {
    float: left;
    padding: 0 10px; }
  .row .cols-1 {
    width: 8.3333333333%; }
  .row .cols-2 {
    width: 16.6666666667%; }
  .row .cols-3 {
    width: 25%; }
  .row .cols-4 {
    width: 33.3333333333%; }
  .row .cols-5 {
    width: 41.6666666667%; }
  .row .cols-6 {
    width: 50%; }
  .row .cols-7 {
    width: 58.3333333333%; }
  .row .cols-8 {
    width: 66.6666666667%; }
  .row .cols-9 {
    width: 75%; }
  .row .cols-10 {
    width: 83.3333333333%; }
  .row .cols-11 {
    width: 91.6666666667%; }
  .row .cols-12 {
    width: 100%; }
  @media (max-width: 1023px) {
    .row .cols-md-1,
    .row .cols-md-2,
    .row .cols-md-3,
    .row .cols-md-4,
    .row .cols-md-5,
    .row .cols-md-6,
    .row .cols-md-7,
    .row .cols-md-8,
    .row .cols-md-9,
    .row .cols-md-10,
    .row .cols-md-11,
    .row .cols-md-12 {
      float: left;
      padding: 0 10px; }
    .row .cols-md-1 {
      width: 8.3333333333%; }
    .row .cols-md-2 {
      width: 16.6666666667%; }
    .row .cols-md-3 {
      width: 25%; }
    .row .cols-md-4 {
      width: 33.3333333333%; }
    .row .cols-md-5 {
      width: 41.6666666667%; }
    .row .cols-md-6 {
      width: 50%; }
    .row .cols-md-7 {
      width: 58.3333333333%; }
    .row .cols-md-8 {
      width: 66.6666666667%; }
    .row .cols-md-9 {
      width: 75%; }
    .row .cols-md-10 {
      width: 83.3333333333%; }
    .row .cols-md-11 {
      width: 91.6666666667%; }
    .row .cols-md-12 {
      width: 100%; } }
  @media (max-width: 767px) {
    .row .text-center-sm {
      text-align: center; }
    .row .cols-sm-1,
    .row .cols-sm-2,
    .row .cols-sm-3,
    .row .cols-sm-4,
    .row .cols-sm-5,
    .row .cols-sm-6,
    .row .cols-sm-7,
    .row .cols-sm-8,
    .row .cols-sm-9,
    .row .cols-sm-10,
    .row .cols-sm-11,
    .row .cols-sm-12 {
      float: left;
      padding: 0 10px; }
    .row .cols-sm-1 {
      width: 8.3333333333%; }
    .row .cols-sm-2 {
      width: 16.6666666667%; }
    .row .cols-sm-3 {
      width: 25%; }
    .row .cols-sm-4 {
      width: 33.3333333333%; }
    .row .cols-sm-5 {
      width: 41.6666666667%; }
    .row .cols-sm-6 {
      width: 50%; }
    .row .cols-sm-7 {
      width: 58.3333333333%; }
    .row .cols-sm-8 {
      width: 66.6666666667%; }
    .row .cols-sm-9 {
      width: 75%; }
    .row .cols-sm-10 {
      width: 83.3333333333%; }
    .row .cols-sm-11 {
      width: 91.6666666667%; }
    .row .cols-sm-12 {
      width: 100%; } }

h3,
h4 {
  margin: 10px 0; }

.for-mobile {
  display: none; }
  @media (max-width: 767px) {
    .for-mobile {
      display: block; } }

.btn, a.btn, input.btn {
  font-family: HelveticaNeueLTStd, serif;
  font-size: 23.3333333333px;
  font-weight: bolder;
  line-height: 52px;
  width: 154px;
  height: 50px;
  border: none;
  background: none; }
  .btn.btn-bleu {
    color: #e7e7e7;
    background-color: #4d92c3; }
  .btn.btn-rouge {
    color: #e7e7e7;
    background-color: #c30120; }
  .btn.btn-md {
    margin-left: 10px;
    font-size: 18.3333333333px; }

a.btn {
  padding: 12px 15px 8px;
  text-align: center; }

div.btn-group > a {
  padding: 0; }

div.btn-group > div,
div.btn-group a {
  float: left; }

@media (max-width: 767px) {
  .btn, a.btn, input.btn {
    display: inline-block;
    margin-bottom: 5px;
    padding: 0; } }

header {
  width: 100%; }
  header .header-top {
    height: 15px;
    background-color: black; }

@media (min-width: 321px) and (max-width: 1023px) {
  header .header-top {
    display: none; } }
  header .header-jumbo {
    overflow: hidden;
    height: 440px;
    background-color: #0169b2; }
    header .header-jumbo img.backgroud {
      display: none; }
    header .header-jumbo .content {
      position: relative; }
      header .header-jumbo .content .header-jumbo--logo {
        position: absolute;
        top: 60px;
        left: 0; }
        header .header-jumbo .content .header-jumbo--logo img {
          width: 288px;
          height: 135px; }
      header .header-jumbo .content .header-jumbo--tel {
        font-family: HelveticaNeueLTStd, serif;
        font-size: 51px;
        font-weight: bold;
        position: absolute;
        top: 60px;
        right: 0;
        width: 372px;
        height: 37px;
        text-align: right;
        text-shadow: -7.8px 4.5px 20.4px rgba(0, 0, 0, 0.35); }
        header .header-jumbo .content .header-jumbo--tel a {
          text-decoration: none;
          color: white; }
      header .header-jumbo .content .header-jumbo--text--jaune {
        position: absolute;
        top: 140px;
        right: 0; }
        header .header-jumbo .content .header-jumbo--text--jaune img {
          height: 200px; }

@media (min-width: 321px) and (max-width: 1023px) {
  header .header-jumbo {
    height: 340px; }
    header .header-jumbo .content .header-jumbo--logo {
      top: 30px;
      left: 20px; }
      header .header-jumbo .content .header-jumbo--logo img {
        width: 288px;
        height: 135px; }
    header .header-jumbo .content .header-jumbo--tel {
      font-size: 51px;
      top: 30px;
      right: 20px; }
    header .header-jumbo .content .header-jumbo--text--jaune {
      position: absolute;
      top: 150px;
      right: 20px; }
      header .header-jumbo .content .header-jumbo--text--jaune img {
        height: 110px; } }
    @media (max-width: 767px) {
      header .header-jumbo {
        height: 300px; }
        header .header-jumbo .content {
          text-align: center; }
          header .header-jumbo .content .header-jumbo--logo {
            position: relative;
            text-align: center;
            top: inherit;
            left: inherit;
            width: 100%;
            margin-top: 20px; }
            header .header-jumbo .content .header-jumbo--logo img {
              width: 288px;
              height: 135px; }
          header .header-jumbo .content .header-jumbo--tel {
            font-size: 48px;
            position: relative;
            top: inherit;
            right: inherit;
            width: 100%;
            text-align: center;
            margin-top: 15px; }
          header .header-jumbo .content .header-jumbo--text--jaune {
            display: none; } }

.menu-bar {
  height: 70px;
  border-top: 5px solid #ffe000;
  background-color: #0169b2; }
  .menu-bar .menu-bar-content ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .menu-bar .menu-bar-content ul li {
      font-family: HelveticaNeueLTStd, serif;
      font-size: 23.3333333333px;
      float: left;
      height: 65px;
      margin: 0;
      padding: 0;
      border-left: 2px solid #1294d0;
      background-color: #0169b2; }
      .menu-bar .menu-bar-content ul li.btn-admin {
        background-color: #014b7f; }
      .menu-bar .menu-bar-content ul li a {
        display: block;
        padding: 23.3333333333px 23.3333333333px;
        color: white; }
      .menu-bar .menu-bar-content ul li:hover {
        background-color: #015a99; }
      .menu-bar .menu-bar-content ul li.active {
        background-color: #ffe000; }
        .menu-bar .menu-bar-content ul li.active a {
          color: black; }
        .menu-bar .menu-bar-content ul li.active:hover {
          background-color: #e6ca00; }

@media (max-width: 767px) {
  .menu-bar.toggle-menu {
    height: 325px; }
  .menu-bar #menu-to-toggle {
    display: none; }
  .menu-bar .menu-bar-content ul li {
    float: inherit; } }

.container {
  min-height: 50%;
  margin-top: 15px;
  padding: 30px;
  background-color: #fff; }
  .container .content--left--col-1 {
    float: left; }
    .container .content--left--col-1 img {
      width: 500px; }
  .container .content--left--col-2 {
    float: right;
    max-width: 700px; }
  .container .content--right--col-1 {
    float: left;
    max-width: 750px; }
  .container .content--right--col-2 {
    float: right; }
    .container .content--right--col-2 img {
      width: 365px; }
  @media (max-width: 1023px) {
    .container .content--left--col-1 {
      float: left;
      width: 40%; }
      .container .content--left--col-1 img {
        width: 100%; }
    .container .content--left--col-2 {
      float: right;
      max-width: 55%; }
    .container .content--right--col-1 {
      float: left;
      max-width: 55%; }
    .container .content--right--col-2 {
      float: right;
      width: 40%; }
      .container .content--right--col-2 img {
        width: 100%; } }
  @media (max-width: 767px) {
    .container .content--left--col-1 {
      float: inherit;
      width: 100%; }
    .container .content--left--col-2 {
      float: inherit;
      max-width: 100%; }
    .container .content--right--col-1 {
      float: inherit;
      max-width: 100%; }
    .container .content--right--col-2 {
      float: inherit;
      width: 100%;
      margin-top: 20px; } }
  .container h2 {
    font-family: HelveticaNeueLTStd, serif;
    font-size: 32px;
    font-weight: 400;
    margin: 30px 0 0;
    padding: 0; }
    .container h2:first-of-type {
      margin-top: 0; }
  .container p {
    font-family: HelveticaNeueLTStd, serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    padding: 0;
    color: black; }
    .container p a {
      text-decoration: none;
      color: black; }
  .container ul {
    font-family: HelveticaNeueLTStd, serif;
    font-size: 21px;
    line-height: 28px;
    padding: 0;
    list-style-position: inside;
    color: #0169b2; }

.container-form {
  min-height: 250px !important; }

input[type=text], input[type=number],
select {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin: 8px 0;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px; }

.checkbox {
  margin: 14px; }
  .checkbox label {
    color: black; }
    .checkbox label input {
      margin-right: 10px; }

select {
  padding: 6px 10px;
  background-color: white !important; }

.form {
  min-height: 525px !important; }

.error input {
  border-color: red; }

.error .error-message {
  color: red; }

form {
  height: 100%;
  color: #0169b2;
  /* input {
         outline: none;
     }


     .radio {
         margin-bottom: 10px;
         label {
             font-size: 18px;

             position: relative;

             display: inline-block;

             margin-right: 15px;
             padding-left: 30px;

             cursor: pointer;

             color: $color-bleu-light;
             &:before {
                 position: absolute;
                 bottom: 1px;
                 left: 0;

                 display: inline-block;

                 width: 16px;
                 height: 16px;
                 margin-right: 10px;

                 content: '';

                 border: 2px solid $color-bleu-light;
                 border-radius: 8px;
                 background-color: #fff;
                 box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, .3), 0 1px 0 0 rgba(255, 255, 255, .8);
             }
         }
         input[type=radio] {
             display: none;
         }
     }
     input[type=radio]:checked + label:before {
         font-family: serif;
         font-size: 30px;
         line-height: 18px;

         content: '\2022';
         text-align: center;

         color: $color-bleu-light;
     }

     input[type='text'],
     input[type='number'] {
         width: 100%;
         height: 50px;

         color: $color-bleu-light;
         border-style: none;
         border-bottom: 2px solid $form-color;
         background: none;

         appearance: none;
         @extend %big-text;
         @include placeholder {
             font-family: HelveticaNeueLTStd, serif;
             font-size: $menu-text-height+px;
             font-weight: 400;

             height: 30px;

             text-decoration: none;
             text-overflow: ellipsis;
         }
         &:focus {
             border-bottom: 5px solid $color-bleu-light;
         }

         &:invalid {
             border-color: $form-color-error;
             box-shadow: none;
         }
     }
     .has-error input {
         color: $form-color-error;
         border-color: $form-color-error;
     }
     textarea {
         width: 100%;
         padding-right: 15px;

         color: $color-bleu-light;
         border: none;
         border-bottom: 2px solid $form-color;
         outline: none;
         background: none;

         @extend %big-text;
         @include placeholder {
             font-family: HelveticaNeueLTStd, serif;
             font-size: $menu-text-height+px;
             font-weight: 400;

             height: 30px;

             text-decoration: none;
             text-overflow: ellipsis;
         }
         &:invalid {
             border-color: $form-color-error;
             box-shadow: none;
         }
         &:focus {
             border-bottom: 5px solid $color-bleu-light;
         }
     }*/ }
  form h3 {
    color: black; }
  form .help-block {
    font-weight: 400;
    padding-bottom: 8px;
    color: #cd2c24; }

.form-success p {
  font-family: HelveticaNeueLTStd, serif;
  font-size: 20px;
  font-weight: 400;
  color: white; }

footer {
  position: relative;
  z-index: 10;
  clear: both;
  height: 140px; }
  @media (max-width: 767px) {
    footer {
      height: 70px; } }
  footer .footer-left {
    float: left;
    width: 10%; }
    @media (max-width: 1023px) {
      footer .footer-left {
        display: none; } }
    footer .footer-left .footer-left-top .footer-left-fb {
      padding: 15px 30px; }
      footer .footer-left .footer-left-top .footer-left-fb a img {
        width: 38px;
        height: 39px; }
  footer .footer-center {
    font-family: HelveticaNeueLTStd, serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 28px;
    float: left;
    width: 80%;
    height: 13px;
    color: #4d92c3; }
    @media (max-width: 1023px) {
      footer .footer-center {
        width: 100%; } }
    @media (max-width: 767px) {
      footer .footer-center .footer-center-top {
        display: none; } }
    footer .footer-center .footer-center-bottom p {
      margin: 0;
      padding-top: 73px;
      text-align: center; }

@media (min-width: 321px) and (max-width: 1023px) {
  footer .footer-center .footer-center-bottom p {
    padding-top: 15px; } }
      @media (max-width: 767px) {
        footer .footer-center .footer-center-bottom p {
          padding-top: 5px; } }
      footer .footer-center .footer-center-bottom p a {
        text-decoration: none;
        color: #4d92c3; }
  footer .footer-right {
    float: left;
    width: 10%;
    height: 191px;
    background: linear-gradient(to top, #0065b2 0%, #0065b2 117px, white 74px, white); }
    @media (max-width: 1023px) {
      footer .footer-right {
        display: none; } }

footer .footer-left .footer-left-top, footer .footer-center .footer-center-top {
  height: 74px;
  background-color: #fff; }

footer .footer-left .footer-left-bottom, footer .footer-center .footer-center-bottom {
  height: 117px;
  background-color: #0065b2; }

@media (min-width: 321px) and (max-width: 1023px) {
  footer .footer-left .footer-left-bottom, footer .footer-center .footer-center-bottom {
    height: 50px; } }
  @media (max-width: 767px) {
    footer .footer-left .footer-left-bottom, footer .footer-center .footer-center-bottom {
      height: 60px; } }
