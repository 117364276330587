footer {
    position: relative;
    z-index: 10;

    clear: both;

    height: 140px;

  @include media('<tablet') {
    height: 140*.5px;
  }
    @extend %clearfix;

  .footer-left {
        float: left;

        width: 10%;

    @include media('<desktop') {
      display: none;
    }
    .footer-left-top {
            @extend %footer-top;
      .footer-left-fb {
                padding: 15px 30px;
        a {
          img {
                        width: 38px;
                        height: 39px;
                    }
                }
            }
        }
    .footer-left-bottom {
            @extend %footer-bottom;
        }
    }
  .footer-center {
        font-family: HelveticaNeueLTStd, serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 28px;

        float: left;

        width: 80%;
        height: 13px;

        color: rgb(77, 146, 195);

    @include media('<desktop') {
      width: 100%;
    }
    .footer-center-top {
      @include media('<tablet') {
        display: none;
      }
            @extend %footer-top;
        }
    .footer-center-bottom {
            @extend %footer-bottom;
      p {
                margin: 0;
                padding-top: 73px;

                text-align: center;

        @include media('>phone', '<desktop') {
          padding-top: 15px;
        }
        @include media('<tablet') {
          padding-top: 5px;
        }
        a {
          text-decoration: none;

          color: rgb(77, 146, 195);
        }
            }
        }
    }
  .footer-right {
        float: left;

        width: 10%;
        height: 117+74+px;

        background: linear-gradient(to top, #0065b2 0%, #0065b2 117px, white 74px, white);

    @include media('<desktop') {
      display: none;
    }
    }
}

%footer-top {
    height: 74px;

    background-color: #fff;
}

%footer-bottom {
    height: 117px;

    background-color: #0065b2;

  @include media('>phone', '<desktop') {
    height: 50px;
  }
  @include media('<tablet') {
    height: 60px;

  }
}
