input[type=text], input[type=number],
select {
    display: inline-block;

    box-sizing: border-box;
    width: 100%;
    margin: 8px 0;
    padding: 6px 10px;

    border: 1px solid #ccc;
    border-radius: 4px;
}

.checkbox {
  label {
    color: black;
    input {
      margin-right: 10px;
    }
  }
  margin: 14px;
}
select {
    padding: 6px 10px;
    background-color: white !important;
}

.form {
    min-height: 525px !important;
}

.error {
  input {
    border-color: red;
  }
  .error-message {
    color: red;
  }
}

form {
    height: 100%;

    color: $color-bleu;
    h3 {
        color: black;
    }
  .help-block {
    font-weight: 400;

    padding-bottom: 8px;

    color: $form-color-error;
  }
    /* input {
         outline: none;
     }


     .radio {
         margin-bottom: 10px;
         label {
             font-size: 18px;

             position: relative;

             display: inline-block;

             margin-right: 15px;
             padding-left: 30px;

             cursor: pointer;

             color: $color-bleu-light;
             &:before {
                 position: absolute;
                 bottom: 1px;
                 left: 0;

                 display: inline-block;

                 width: 16px;
                 height: 16px;
                 margin-right: 10px;

                 content: '';

                 border: 2px solid $color-bleu-light;
                 border-radius: 8px;
                 background-color: #fff;
                 box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, .3), 0 1px 0 0 rgba(255, 255, 255, .8);
             }
         }
         input[type=radio] {
             display: none;
         }
     }
     input[type=radio]:checked + label:before {
         font-family: serif;
         font-size: 30px;
         line-height: 18px;

         content: '\2022';
         text-align: center;

         color: $color-bleu-light;
     }

     input[type='text'],
     input[type='number'] {
         width: 100%;
         height: 50px;

         color: $color-bleu-light;
         border-style: none;
         border-bottom: 2px solid $form-color;
         background: none;

         appearance: none;
         @extend %big-text;
         @include placeholder {
             font-family: HelveticaNeueLTStd, serif;
             font-size: $menu-text-height+px;
             font-weight: 400;

             height: 30px;

             text-decoration: none;
             text-overflow: ellipsis;
         }
         &:focus {
             border-bottom: 5px solid $color-bleu-light;
         }

         &:invalid {
             border-color: $form-color-error;
             box-shadow: none;
         }
     }
     .has-error input {
         color: $form-color-error;
         border-color: $form-color-error;
     }
     textarea {
         width: 100%;
         padding-right: 15px;

         color: $color-bleu-light;
         border: none;
         border-bottom: 2px solid $form-color;
         outline: none;
         background: none;

         @extend %big-text;
         @include placeholder {
             font-family: HelveticaNeueLTStd, serif;
             font-size: $menu-text-height+px;
             font-weight: 400;

             height: 30px;

             text-decoration: none;
             text-overflow: ellipsis;
         }
         &:invalid {
             border-color: $form-color-error;
             box-shadow: none;
         }
         &:focus {
             border-bottom: 5px solid $color-bleu-light;
         }
     }*/
}

%big-text {
    font-family: HelveticaNeueLTStd, serif;
    font-size: $menu-text-height+px;
    font-weight: 300;
}

.form-success {
    p {
        font-family: HelveticaNeueLTStd, serif;
        font-size: 20px;
        font-weight: 400;

        color: white;
    }
}

