.menu-bar {
    height: $menu-height+px;

    border-top: $menu-border-top+px solid #ffe000;
    background-color: rgb(1, 105, 178);
    .menu-bar-content {
        ul {
            margin: 0;
            padding: 0;

            list-style-type: none;
            li {
                font-family: HelveticaNeueLTStd, serif;
                font-size: $menu-text-height+px;

                float: left;

                height: $menu-btn-height+px;
                margin: 0;
                padding: 0;

                border-left: 2px solid #1294d0;
                background-color: $color-bleu;
                &.btn-admin {
                    background-color: darken($color-bleu, 10);
                }
                a {
                    display: block;

                    padding: $menu-btn-padding-top+px $menu-btn-padding-top+px;

                    color: white;
                }
                &:hover {
                    background-color: darken($color-bleu, 5);
                }
                &.active {
                    background-color: $color-jaune;
                    a {
                        color: black;
                    }
                    &:hover {
                        background-color: darken($color-jaune, 5);
                    }
                }
            }
        }
    }
}

@include media('<tablet') {
  .menu-bar {
    &.toggle-menu {
      height: $menu-btn-height*5+px;
    }
    #menu-to-toggle {
      display: none;
    }
    .menu-bar-content {
      ul {
        li {
          float: inherit;
        }
      }
    }
  }
}
