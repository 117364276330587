@import '../../node_modules/normalize-scss/sass/_normalize.scss';
@import '../../node_modules/include-media/dist/_include-media.scss';

@include normalize();

/* VARIABLES */
$color-jaune: rgb(255, 224, 0);
$color-bleu: rgb(1, 105, 178);
$color-bleu-light: rgb(77, 146, 195);
;
$color-bgc: #e7e7e7;

/* VARIABLES menu */
$menu-height: 70;
$menu-border-top: 5;
$menu-btn-height: $menu-height - $menu-border-top;
$menu-text-height: $menu-height/3;
$menu-btn-padding-top: ($menu-height - $menu-text-height)/2;

$form-color: #2f93c8;
$form-color-error: #cd2c24;

/* VARIABLES breakpoints */
$phone: 640;
$tablet: 768;
$desktop: 1024;
$breakpoints: (phone: $phone px,
        tablet: $tablet px,
        desktop: $desktop px) !default;

@import '_fonts';
@import '_mixins';
@import '_base';
@import '_boutton';
@import '_header';
@import '_nav-menu';
@import '_content';
@import '_form';
@import '_footer';
