header {
  width: 100%;
  .header-top {
    height: 15px;

    background-color: black;

    @include media('>phone', '<desktop') {
      display: none;
    }
    }
  .header-jumbo {
    overflow: hidden;

    height: 440px;

    background-color: $color-bleu;

    img.backgroud {
      display: none;
        }
    .content {
      position: relative;
      .header-jumbo--logo {
        position: absolute;
        top: 60px;
        left: 0;
        img {
          width: 288px;
          height: 135px;
        }
      }
      .header-jumbo--tel {
        font-family: HelveticaNeueLTStd, serif;
        font-size: 51px;
        font-weight: bold;

        position: absolute;
        top: 60px;
        right: 0;

        width: 372px;
        height: 37px;

        text-align: right;

        text-shadow: -7.8px 4.5px 20.4px rgba(0, 0, 0, .35);
        a {
          text-decoration: none;

          color: white;
        }
      }

      .header-jumbo--text--jaune {
        position: absolute;
        top: 140px;
        right: 0;
        img {
          height: 200px;
        }
      }
        }
    @include media('>phone', '<desktop') {
      height: 340px;
      .content {
        .header-jumbo--logo {
          top: 30px;
          left: 20px;
          img {
            width: 288px;
            height: 135px;
          }
          }
        .header-jumbo--tel {
          font-size: 51px;

          top: 30px;
          right: 20px;
        }

        .header-jumbo--text--jaune {
          position: absolute;
          top: 150px;
          right: 20px;
          img {
            height: 110px;
          }
          }
        }
      }
    @include media('<tablet') {
      height: 300px;
      .content {
        text-align: center;
        .header-jumbo--logo {
          position: relative;
          text-align: center;
          top: inherit;
          left: inherit;
          width: 100%;
          margin-top: 20px;
          img {
            width: 288px;
            height: 135px;
          }
        }
        .header-jumbo--tel {
          font-size: 48px;
          position: relative;
          top: inherit;
          right: inherit;
          width: 100%;
          text-align: center;
          margin-top: 15px;
        }

        .header-jumbo--text--jaune {
          display: none;
        }
      }
      }
    }
}
