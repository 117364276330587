html,
body,
.content
{
    height: 100%;
    margin: 0;
    padding: 0;
}

body
{
    background-color: $color-bgc;
}

*
{
    box-sizing: border-box;
}

a
{
    text-decoration: none;
}

.content
{
    width: 1290px;
    height: auto;
    max-height: 80%;
    margin: 0 auto;

    @extend %clearfix;
  @include media('<desktop') {
    width: 100%;
    max-height: inherit;
  }
}

.row
{
    width: 100%;

    @extend %clearfix;
    .cols-1,
    .cols-2,
    .cols-3,
    .cols-4,
    .cols-5,
    .cols-6,
    .cols-7,
    .cols-8,
    .cols-9,
    .cols-10,
    .cols-11,
    .cols-12
    {
        float: left;

        padding: 0 10px;
    }
    .cols-1
    {
        width: percentage(1/12);
    }
    .cols-2
    {
        width: percentage(2/12);
    }
    .cols-3
    {
        width: percentage(3/12);
    }
    .cols-4
    {
        width: percentage(4/12);
    }
    .cols-5
    {
        width: percentage(5/12);
    }
    .cols-6
    {
        width: percentage(6/12);
    }
    .cols-7
    {
        width: percentage(7/12);
    }
    .cols-8
    {
        width: percentage(8/12);
    }
    .cols-9
    {
        width: percentage(9/12);
    }
    .cols-10
    {
        width: percentage(10/12);
    }
    .cols-11
    {
        width: percentage(11/12);
    }
    .cols-12
    {
        width: percentage(12/12);
    }
  @include media('<desktop') {
    .cols-md-1,
    .cols-md-2,
    .cols-md-3,
    .cols-md-4,
    .cols-md-5,
    .cols-md-6,
    .cols-md-7,
    .cols-md-8,
    .cols-md-9,
    .cols-md-10,
    .cols-md-11,
    .cols-md-12 {
      float: left;

      padding: 0 10px;
    }
    .cols-md-1 {
      width: percentage(1/12);
    }
    .cols-md-2 {
      width: percentage(2/12);
    }
    .cols-md-3 {
      width: percentage(3/12);
    }
    .cols-md-4 {
      width: percentage(4/12);
    }
    .cols-md-5 {
      width: percentage(5/12);
    }
    .cols-md-6 {
      width: percentage(6/12);
    }
    .cols-md-7 {
      width: percentage(7/12);
    }
    .cols-md-8 {
      width: percentage(8/12);
    }
    .cols-md-9 {
      width: percentage(9/12);
    }
    .cols-md-10 {
      width: percentage(10/12);
    }
    .cols-md-11 {
      width: percentage(11/12);
    }
    .cols-md-12 {
      width: percentage(12/12);
    }
  }
  @include media('<tablet') {
    .text-center-sm {
      text-align: center;
    }
    .cols-sm-1,
    .cols-sm-2,
    .cols-sm-3,
    .cols-sm-4,
    .cols-sm-5,
    .cols-sm-6,
    .cols-sm-7,
    .cols-sm-8,
    .cols-sm-9,
    .cols-sm-10,
    .cols-sm-11,
    .cols-sm-12 {
      float: left;

      padding: 0 10px;
    }
    .cols-sm-1 {
      width: percentage(1/12);
    }
    .cols-sm-2 {
      width: percentage(2/12);
    }
    .cols-sm-3 {
      width: percentage(3/12);
    }
    .cols-sm-4 {
      width: percentage(4/12);
    }
    .cols-sm-5 {
      width: percentage(5/12);
    }
    .cols-sm-6 {
      width: percentage(6/12);
    }
    .cols-sm-7 {
      width: percentage(7/12);
    }
    .cols-sm-8 {
      width: percentage(8/12);
    }
    .cols-sm-9 {
      width: percentage(9/12);
    }
    .cols-sm-10 {
      width: percentage(10/12);
    }
    .cols-sm-11 {
      width: percentage(11/12);
    }
    .cols-sm-12 {
      width: percentage(12/12);
    }
  }
}

h3,
h4 {
  margin: 10px 0;
}

.clearfix {
  @extend %clearfix;
}

.for-mobile {
  display: none;
  @include media('<tablet') {
    display: block;
  }
}